<template>
  <UiButton
    v-editable="blok"
    :link="blok.link"
    :target="blok.link.target"
    :theme="theme"
    :label="blok.label"
    :variant="variant"
    :left-icon="leftIcon"
    :right-icon="rightIcon"
    :size="size"
    :mobile-size="mobileSize"
    :custom-color-theme="customTheme"
    :horizontal-alignment="blok.horizontalAlignment"
  />
</template>

<script setup lang="ts">
import type { UiButton } from '@/components/ui/types'
import type { Icon } from '@/components/ui/UiIcon.vue'
import type {
  BlockCtaButtonStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'
import { getCommunityThemeButtonCSSVars } from '@/utils/theme-utils'

const props = defineProps<{
  blok: BlockCtaButtonStoryblok
  mobileSize?: UiButton.Size
  customColorTheme?: UiButton.CustomTheme
  pageSectionTheme?: PageSectionStoryblok['theme']
}>()
const { communityContent } = storeToRefs(useCurrentStoryStore())
const leftIcon = computed(() => props.blok.leftIcon as Icon)
const rightIcon = computed(() => props.blok.rightIcon as Icon)
const variant = computed(() => props.blok.variant as UiButton.Variant)
const size = computed(() => props.blok.size as UiButton.Size)
const theme = computed(() => props.blok.theme as UiButton.Theme)
const customTheme = computed(() => {
  if (props.customColorTheme) {
    return props.customColorTheme
  } else if (communityContent.value) {
    const theme = props.pageSectionTheme === 'dark' ? 'light' : 'dark'
    return getCommunityThemeButtonCSSVars(
      variant.value,
      theme,
      communityContent.value,
    )
  }

  return undefined
})
</script>
