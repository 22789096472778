import type { ISbStoryData } from 'storyblok'
import type {
  CarAccomodation,
  ContentTypeAgentStoryblok,
  ContentTypeCommunityStoryblok,
  ContentTypePropertyStoryblok,
  ContentTypeStateStoryblok,
  DataAddressStoryblok,
  PropertyCardProps,
} from '@/types/storyblok'
import type {
  ISbDatasourceEntries,
  IGenericSbStories,
} from '@/composables/useStoryblokHelpers'

export const fetchAllStates = async () => {
  const { fetchAsyncData } = useStoryblokHelpers()
  const stateApiOptions = computed<FetchAPIOptions>(() => ({
    starts_with: 'communities/',
    content_type: 'ContentTypeState',
  }))

  const [{ data: states }, { states: orderedStates }] = await Promise.all([
    fetchAsyncData<ContentTypeStateStoryblok>('stories', stateApiOptions, {
      getCachedData(key, nuxtApp) {
        return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
      },
    }),
    useConfigStore().asyncConfig(),
  ])

  return toRef(
    sortGenericSbStoriesByUUID<ContentTypeStateStoryblok>(
      states.value,
      orderedStates.value as string[],
    ),
  )
}

export const fetchAllCommunities = async () => {
  const { fetchAsyncData } = useStoryblokHelpers()
  const communityApiOptions = computed<FetchAPIOptions>(() => ({
    starts_with: 'communities/',
    content_type: 'ContentTypeCommunity',
    sort_by: 'name:asc',
  }))

  const [{ data: communities }, { communities: orderedCommunities }] =
    await Promise.all([
      fetchAsyncData<ContentTypeCommunityStoryblok>(
        'stories',
        communityApiOptions,
        {
          getCachedData(key, nuxtApp) {
            return nuxtApp.payload.data[key] || nuxtApp.static.data[key]
          },
        },
      ),
      useConfigStore().asyncConfig(),
    ])

  return toRef(
    sortGenericSbStoriesByUUID<ContentTypeCommunityStoryblok>(
      communities.value,
      orderedCommunities.value as string[],
    ),
  )
}

export const resolveContentPropertyType = (
  propertyType: ContentTypePropertyStoryblok['propertyType'],
) => {
  const { propertyTypeEntries } = storeToRefs(usePropertyTypeEntriesStore())
  if (propertyTypeEntries.value && propertyType) {
    const foundEntry = propertyTypeEntries.value.find(
      (entry: DatasourceEntry) =>
        entry.value.toLowerCase() === propertyType.toLowerCase(),
    )

    return foundEntry?.dimension_value
  }

  return undefined
}

export const dataAddressToString = (address?: DataAddressStoryblok) => {
  if (!address) return undefined

  return [
    address.line1,
    address.line2,
    address.suburb,
    address.city,
    address.state?.toUpperCase(),
    address.postCode,
  ].join(' ')
}

export const resolveErpPropertyType = (
  propertyType?: string,
): PropertyType | undefined => {
  const { propertyTypeEntries } = storeToRefs(usePropertyTypeEntriesStore())

  if (propertyTypeEntries.value && propertyType) {
    const foundEntry = propertyTypeEntries.value.find(
      (entry: DatasourceEntry) =>
        entry.dimension_value
          ?.split(',')
          .find(
            (status) => status.toLowerCase() === propertyType.toLowerCase(),
          ),
    )

    return foundEntry?.name as PropertyType
  }

  return undefined
}

export const carSpacesToCarAccomodation = (
  carSpaces: number,
): CarAccomodation[] | undefined => {
  switch (carSpaces) {
    case 0:
      return ['No Garage']
    case 1:
      return ['Car Port', 'Single Lockup Garage']
    case 2:
      return [
        'Double Car Port',
        'Double Lockup Garage',
        'Lockup Garage and Carport',
        'Tandem Lockup Garage',
      ]
    case 3:
      return ['Triple Lockup garage']
    default:
      return undefined
  }
}
export const carAccomodationToCarSpaces = (
  carAccomodation?: CarAccomodation,
) => {
  switch (carAccomodation) {
    case 'Car Port':
    case 'Single Lockup Garage':
      return 1
    case 'Double Car Port':
    case 'Double Lockup Garage':
    case 'Lockup Garage and Carport':
    case 'Tandem Lockup Garage':
      return 2
    case 'Triple Lockup garage':
      return 3
    case 'No Garage':
    default:
      return 0
  }
}
export const resolveErpCarSpaces = (
  propertyType?: PropertyType,
  cpAllocation?: number,
  carAccomodation?: CarAccomodation,
) => {
  switch (propertyType) {
    case 'Apartment':
      return cpAllocation
    case 'Home':
      return carAccomodationToCarSpaces(carAccomodation)
    case 'Land':
    default:
      return undefined
  }
}

export type ISbDatasourceEntriesResponse = {
  name: string
  entries: ISbDatasourceEntries | null
}

export const fetchDatasourceEntries = async (
  slug: string,
  dimension?: string,
): Promise<ISbDatasourceEntriesResponse> => {
  const { fetchAsyncData } = useStoryblokHelpers()
  const { data: datasourceEntries } = await fetchAsyncData<
    undefined,
    ISbDatasourceEntries
  >('datasource_entries', { datasource: slug, dimension })

  return { name: slug, entries: datasourceEntries.value }
}

export function sortGenericSbStoriesByUUID<T>(
  storiesObject: IGenericSbStories<T> | null,
  sortedItems: string[],
): IGenericSbStories<T> | null {
  if (!storiesObject) {
    return null
  }

  const storiesMap = new Map(
    storiesObject.data.stories.map((story) => [story.uuid, story]),
  )

  // Sort the stories based on sortedItems and append unmatched stories at the end
  const sortedStories = [
    ...(sortedItems
      .map((uuid) => storiesMap.get(uuid))
      .filter(Boolean) as ISbStoryData<T>[]),
    ...storiesObject.data.stories.filter(
      (story) => !sortedItems.includes(story.uuid),
    ),
  ]

  return {
    ...storiesObject,
    data: {
      ...storiesObject.data,
      stories: sortedStories,
    },
  } as IGenericSbStories<T>
}

export function generateHouseAndLandPackageCardProps(
  hlpStory: ISbStoryData,
  overrides?: Partial<PropertyCardProps>,
): PropertyCardProps | undefined {
  if (
    !isContentTypeHlpStoryblok(hlpStory) ||
    !isContentTypePropertyStoryblok(hlpStory.content.associatedLand)
  ) {
    return undefined
  }

  const { propertyContentTypeProps: associatedLandProperty } = usePropertyData(
    hlpStory.content.associatedLand.content,
  )

  return {
    ...associatedLandProperty.value.propertyCardProps,
    bookInspectionLink: hlpStory.content.bookInspectionButtonLink ?? undefined,
    valueProposition:
      hlpStory.content.valueProposition ??
      associatedLandProperty.value.propertyCardProps?.valueProposition,
    bedrooms:
      parseFloat(hlpStory.content.bedrooms ?? '0') ??
      associatedLandProperty.value.propertyCardProps?.bedrooms,
    bathrooms:
      parseFloat(hlpStory.content.bathrooms ?? '0') ??
      associatedLandProperty.value.propertyCardProps?.bathrooms,
    carSpaces:
      parseFloat(hlpStory.content.parkingSpaces ?? '0') ??
      associatedLandProperty.value.propertyCardProps?.carSpaces,
    price: parseFloat(hlpStory.content.price ?? '0'),
    agents: associatedLandProperty.value.agents
      ? associatedLandProperty.value.agents.map(
          (agent: ISbStoryData<ContentTypeAgentStoryblok>) => agent.content,
        )
      : undefined,
    builders: isContentTypeBuilderStoryblok(hlpStory.content.builders)
      ? [hlpStory.content.builders.content]
      : undefined,
    badge:
      hlpStory.content.badge ??
      associatedLandProperty.value.propertyCardProps?.badge,
    width: undefined,
    depth: undefined,
    image: hlpStory.content.cardImage?.filename
      ? hlpStory.content.cardImage
      : associatedLandProperty.value.propertyCardProps?.image,
    status: undefined,
    ...overrides,
  }
}
