<template>
  <div
    v-editable="blok"
    :class="{
      // Fluid container
      'container-fluid__content': isFluidContentColumn,
      'lg:px-5': isFluidContentColumn,
      'cols-4': isFluidContentColumn && !useEqualColumns,
      'justify-center': verticalAlignment === 'center',
      'justify-start': verticalAlignment === 'top',
      'justify-end': verticalAlignment === 'bottom',
      'w-full flex-1': isFluidContainer && blok.isLarge,
      'flex justify-end': index === 1 && blok.isLarge && isFluidContainer,

      // Fixed container
      'col-span-full lg:col-span-4':
        !isFluidContentColumn && !isSingleColumnLayout && !useEqualColumns,
      'col-span-full': isSingleColumnLayout,
      'col-span-full lg:col-span-8':
        !isFluidContainer && blok.isLarge && !isSingleColumnLayout,
      'lg:pr-6': !isFluidContainer && !blok.isLarge && !isSingleColumnLayout,

      'col-span-full lg:col-span-6': !isFluidContentColumn && useEqualColumns,

      // Spacing
      'py-[--blockSpacing]': !isFluidContainer || !isFirstBlockMedia,

      // Ensure the small column is always placed first on mobile when there are multiple columns
      'order-first lg:order-none': index > 0 && !blok.isLarge,
    }"
    class="c-column flex flex-col gap-9 lg:gap-10"
  >
    <StoryblokComponent
      v-for="content in blok.content"
      :key="content._uid"
      :blok="content"
      :page-section-theme="pageSectionTheme"
      :column-index="index"
      :is-fluid-container="isFluidContainer"
    />
  </div>
</template>

<script setup lang="ts">
import type {
  BlockColumnsStoryblok,
  BlockColumnsColumnStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'

const props = defineProps<{
  blok: BlockColumnsColumnStoryblok
  pageSectionTheme: PageSectionStoryblok['theme']
  pageSectionSpacing: PageSectionStoryblok['spacing']
  verticalAlignment: BlockColumnsStoryblok['verticalAlignment']
  isFluidContainer: boolean
  isSingleColumnLayout: boolean
  index: number
  useEqualColumns: boolean
}>()
const isFluidContentColumn = computed(
  () => props.isFluidContainer && !props.blok.isLarge,
)
const isFirstBlockMedia = computed(() => {
  return (
    props.blok.content?.[0]?.component === 'BlockMediaImage' ||
    props.blok.content?.[0]?.component === 'BlockGallery' ||
    props.blok.content?.[0]?.component === 'BlockMap'
  )
})
</script>
