const _white = "#FFFFFF"
const _black = "#000000"
const _transparent = "transparent"
const _current = "currentColor"
const _primary = {"500":"#00843D","800":"#0E1E16"}
const _red = "#EF413E"
const _orange = "#F58357"
const _yellow = "#FFC20E"
const _green = "#4EB857"
const _eggshell = "#56C5D0"
const _secondary = "#6c6b69"
const _tertiary = "#CCCBC8"
const _gray = {"100":"#FAF9F5","200":"#F5F4F0","300":"#E0DDD3","400":"#C7C0AA","500":"#85857C","700":"#595956","900":"#242422"}
const _auto = "auto"
const config = { "white": _white, "black": _black, "transparent": _transparent, "current": _current, "primary": _primary, "red": _red, "orange": _orange, "yellow": _yellow, "green": _green, "eggshell": _eggshell, "secondary": _secondary, "tertiary": _tertiary, "gray": _gray, "auto": _auto, "coral-reef": "#C9BAA7",  }
export { config as default, _white, _black, _transparent, _current, _primary, _red, _orange, _yellow, _green, _eggshell, _secondary, _tertiary, _gray, _auto }