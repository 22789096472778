<template>
  <footer
    :class="{
      'pb-[--communityTabBarPaddingOffset] lg:pb-[4.5rem]': isCommunity,
      'pb-[4.5rem]': !isCommunity,
    }"
    class="border-t-2 border-gray-200 pt-9"
  >
    <div class="container flex flex-col gap-9 lg:gap-y-[3.75rem]">
      <div class="grid grid-cols-12 gap-y-8 lg:gap-x-9">
        <template v-if="!isLandingPageContentType">
          <ul
            v-for="(menu, menuIndex) in footerNavigation"
            :key="menu._uid"
            :class="{
              'text-primary-800 pt-5 text-sm font-medium lg:text-base':
                menuIndex === 0,
              'text-sm text-gray-700': menuIndex === 1,
            }"
            class="col-span-full grid grid-cols-2 gap-x-9 gap-y-3 lg:col-span-5 lg:gap-y-6 lg:pt-5"
            role="menu"
          >
            <li
              v-for="menuItem in menu.menuItems"
              :key="menuItem._uid"
              role="none"
            >
              <StoryblokComponent :blok="menuItem" />
            </li>
          </ul>
        </template>
        <template v-else>
          <!-- eslint-disable vue/no-v-html -->
          <div
            class="text-secondary col-span-full max-w-[936px] text-xs lg:col-span-10"
            v-html="landingPageDisclaimer"
          />
          <!-- eslint-enable -->
        </template>
        <figure
          class="order-first col-span-full flex lg:order-none lg:col-span-2"
        >
          <NuxtLink to="/" class="inline-block w-full max-w-[11.125rem]">
            <NuxtImg
              :src="footerLogo.filename"
              :alt="footerLogo.alt"
              class="w-full"
            />
          </NuxtLink>
        </figure>
      </div>
      <div class="text-secondary flex flex-col gap-3 text-xs">
        <p>{{ copyright }}</p>
        <p>
          Made by
          <a
            href="https://www.grade.net.au"
            rel="external"
            class="ease-out-expo hover:text-primary-500 focus:text-primary-500 font-bold transition-colors duration-700"
          >
            Grade
          </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
// we need to wait for the story to be ready before we can use it
await useCurrentStoryStore().isReady

const { footerNavigation, copyright, footerLogo } =
  await useConfigStore().asyncConfig()

const { story, communityContent } = storeToRefs(useCurrentStoryStore())
const isCommunity = computed(() => {
  return story.value?.content?.component === 'ContentTypeCommunity'
})
const isLandingPageContentType = computed(() =>
  isContentTypeLandingPageStoryblok(story.value),
)
const landingPageDisclaimer = computed(() => {
  return isLandingPageContentType.value
    ? renderRichText(communityContent.value?.landingPageDisclaimer)
    : undefined
})
</script>
