<template>
  <div>
    <div
      v-editable="blok"
      :class="{
        container: !blok.useFluidWidth,
      }"
    >
      <div
        :class="{
          'container-fluid': blok.useFluidWidth,
          'lg:pl-0': useEqualColumns,
          'flex w-full flex-col lg:flex-row': blok.useFluidWidth,
          'grid grid-cols-12': !blok.useFluidWidth,
          'container-fluid--reverse':
            blok.useFluidWidth && blok.columns[0]?.isLarge,
          'items-start': blok.verticalAlignment === 'top',
          'items-center': blok.verticalAlignment === 'center',
          'items-end': blok.verticalAlignment === 'bottom',
        }"
        class="lg:gap-x-9"
      >
        <StoryblokComponent
          v-for="(column, columnIndex) in blok.columns"
          :key="column._uid"
          :blok="column"
          :index="columnIndex"
          :vertical-alignment="blok.verticalAlignment"
          :page-section-theme="pageSectionTheme"
          :page-section-spacing="pageSectionSpacing"
          :is-fluid-container="blok.useFluidWidth"
          :is-single-column-layout="blok.columns.length === 1"
          :use-equal-columns="useEqualColumns"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  BlockColumnsStoryblok,
  PageSectionStoryblok,
} from '@/types/storyblok'

const props = defineProps<{
  blok: BlockColumns
  pageSectionTheme: PageSectionStoryblok['theme']
  pageSectionSpacing: PageSectionStoryblok['spacing']
}>()

const useEqualColumns = computed(() => {
  const { columns } = props.blok

  if (columns.length === 1) {
    return false
  }

  return !columns.some((column) => column.isLarge)
})

export interface BlockColumns extends BlockColumnsStoryblok {
  verticalAlignment: 'top' | 'center' | 'bottom'
}
</script>
